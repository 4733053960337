import React, {Fragment} from 'react';
import {ThemeProvider} from 'styled-components';
import Sticky from 'react-stickynode';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {portfolioTheme} from 'common/theme/portfolio';
import {ResetCSS} from 'common/assets/css/style';
import {
    GlobalStyle,
    ContentWrapper,
} from 'containers/Portfolio/portfolio.style';

import BannerSection from 'containers/Portfolio/Banner';
import Navbar from 'containers/Portfolio/Navbar';
import PortfolioShowcase from 'containers/Portfolio/PortfolioShowcase';
import ProcessSection from 'containers/Portfolio/Process';
// import TestimonialSection from 'containers/Portfolio/Testimonial';
import ClientsSection from 'containers/Portfolio/Clients';
// import ContactSection from 'containers/Portfolio/Contact';
import Footer from 'containers/Portfolio/Footer';
import Seo from 'components/seo';
import ArVrSection from "../containers/Portfolio/ArVr";

const Portfolio = () => {
    return (
        <ThemeProvider theme={portfolioTheme}>
            <Fragment>
                <Seo title="IV | Where Vision Becomes Reality"
                     description="Mobile and Web development agency. Turning Vision into Reality."
                     keywords={['develop app agency',
                         'app',
                         'agency', 'agancy',
                         'development',
                         'european',
                         'development agency',
                         'develop',
                         'create app', 'develop app',
                         'european development agency',
                         'mobile app agencies',
                         'vr',
                         'ar',
                         'spatial computing',
                         'spatial computer',
                         'apple Vision Pro',
                         'vision Pro',
                         'apple Vision',
                         'meta Quest 3',
                         'meta Quest',
                         'quest 3',
                         'augmented reality',
                         'virtual reality']}
                />
                <ResetCSS/>
                <GlobalStyle/>

                <ContentWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <BannerSection/>
                    <ProcessSection/>
                    <ArVrSection/>
                    <PortfolioShowcase/>
                    {/*<SkillSection />*/}
                    {/*<CallToAction />*/}
                    {/*TODO: Enable testimonial once feedback is ready*/}
                    {/*<TestimonialSection />*/}
                    <ClientsSection/>
                    {/*<ContactSection />*/}
                    <Footer/>
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};
export default Portfolio;
