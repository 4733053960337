import React from 'react';
import PropTypes from 'prop-types';
import {useStaticQuery, graphql} from 'gatsby';
import Icon from 'react-icons-kit';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SocialProfile from '../SocialProfile';
import BannerWrapper from './banner.style';

import {cornerDownRight} from 'react-icons-kit/feather/cornerDownRight';

const BannerSection = ({
                           row,
                           contentArea,
                           imageArea,
                           greetingStyle,
                           nameStyle,
                           designationStyle,
                           aboutStyle,
                           roleStyle,
                           roleWrapper,
                       }) => {
    const data = useStaticQuery(graphql`
    query {
      splash: file(relativePath: { eq: "image/IV.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1024
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      contactJson {
        socialProfiles {
         display
         items {
          icon
          url
           }
        }
      }
      sectionsJson {
        banner {
          greeting
          title
          designation
          showArrowSection
          body
        }
      }
    }
  `);

    const splashData = data.splash;
    const bannerData = data.sectionsJson.banner;
    const socialData = data.contactJson.socialProfiles;

    return (
        <BannerWrapper id="banner_section">
            <Container noGutter mobileGutter width="1200px">
                <Box {...row}>
                    <Box {...contentArea}>
                        <Heading content={bannerData.greeting} {...greetingStyle} />
                        <Heading content={bannerData.title} {...nameStyle} />
                        <Heading content={bannerData.designation} {...designationStyle} />
                        {bannerData.showArrowSection && <Box {...roleWrapper}>
                            <Icon
                                icon={cornerDownRight}
                                style={{color: '#D728CA'}}
                                size={22}
                            />
                            <Heading content="A place, where vision becomes reality" {...roleStyle} />
                        </Box>}
                        <Text
                            content={bannerData.body}
                            {...aboutStyle}
                        />
                        {socialData.display && <SocialProfile items={socialData.items}/>}
                    </Box>
                    <Box {...imageArea} className="image_area">
                        <GatsbyImage
                            src={(splashData !== null) ? splashData.childImageSharp.gatsbyImageData : {}}
                            alt="Invoid Vision BV"
                        />
                    </Box>
                </Box>
            </Container>
        </BannerWrapper>
    );
};

BannerSection.propTypes = {
    row: PropTypes.object,
    contentArea: PropTypes.object,
    imageArea: PropTypes.object,
    greetingStyle: PropTypes.object,
    nameStyle: PropTypes.object,
    designationStyle: PropTypes.object,
    aboutStyle: PropTypes.object,
    roleStyle: PropTypes.object,
    roleWrapper: PropTypes.object,
};

BannerSection.defaultProps = {
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        alignItems: 'stretch',
    },
    contentArea: {
        width: ['100%', '100%', '50%', '40%'],
        p: ['65px 0 80px 0', '65px 0 80px 0', '80px 0 60px 0', '0'],
        flexBox: true,
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    imageArea: {
        width: ['100%', '100%', '50%', '60%'],
        flexBox: true,
        alignItems: 'flex-end',
    },
    greetingStyle: {
        as: 'h3',
        color: '#fff',
        fontSize: ['18px', '18px', '18px', '20px', '30px'],
        fontWeight: '500',
        mb: '8px',
    },
    nameStyle: {
        as: 'h2',
        color: '#fff',
        fontSize: ['38px', '38px', '44px', '60px', '80px'],
        fontWeight: '800',
        fontFamily: 'Jula',
        mb: '6px',
    },
    designationStyle: {
        as: 'h3',
        color: '#fff',
        fontSize: ['18px', '18px', '18px', '20px', '30px'],
        fontWeight: '700',
        mb: ['30px', '30px', '25px', '30px', '30px'],
    },
    roleWrapper: {
        flexBox: true,
        mb: '28px',
    },
    roleStyle: {
        as: 'h4',
        fontSize: ['18px', '18px', '18px', '18px', '20px'],
        fontWeight: '500',
        color: '#fff',
        mb: '0',
        ml: '10px',
    },
    aboutStyle: {
        fontSize: ['15px', '15px', '15px', '16px', '16px'],
        fontWeight: '400',
        color: '#fff',
        lineHeight: '1.5',
        mb: '50px',
    },
};

export default BannerSection;
