import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Tabs, { TabPane } from "rc-tabs";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import GlideCarousel from "common/components/GlideCarousel";
import GlideSlide from "common/components/GlideCarousel/glideSlide";
import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,
  PortfolioLink,
  BuiltWith,
  PortfolioMeta,
  MetaItem,
} from "./portfolioShowcase.style";
import { PrevButton, NextButton } from "../portfolio.style";

const PortfolioShowcase = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
}) => {
  const data = useStaticQuery(graphql`
    query {
      sectionsJson {
        portfolioShowcase {
          title
          portfolioItem {
            title
            description
            link
            serviceDone {
              content
            }
            featuredOn {
              title
              link
            }
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  height: 449
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `);

  const showcase = data.sectionsJson.portfolioShowcase;

  //Carousel Options
  const carouselOptions = {
    type: "carousel",
    perView: 1,
    gap: 0,
    animationDuration: 900,
  };

  return (
    <Box {...sectionWrapper} as="section" id="portfolio_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Where Vision already became the reality" />
          <Text
            {...secDescription}
            content="Year after year, Invoid Vision continues assiting clients in digital world. Hight quality design and development, but yet budget flexible and client oriented."
          />
        </Box>

        <PortfolioShowcaseWrapper key={"showcase-wrapper"}>
          <Tabs
          // renderTabBar={() => <ScrollableInkTabBar />}
          // renderTabContent={() => <TabContent animated={false} />}
          >
            {showcase.map((tabItem, index) => (
              <TabPane
                tab={
                  <Text
                    content={tabItem.title}
                    data-text={tabItem.title}
                    as="span"
                  />
                }
                key={index + 1}
              >
                <GlideCarousel
                  carouselSelector={`portfolio-showcase-carousel-${index + 1}`}
                  key={`PortfolioShowcaseCarousel-${index}`}
                  options={carouselOptions}
                  prevButton={
                    <PrevButton>
                      <span />
                    </PrevButton>
                  }
                  nextButton={
                    <NextButton>
                      <span />
                    </NextButton>
                  }
                >
                  <>
                    {tabItem.portfolioItem.map((portfolioItem, index) => (
                      <GlideSlide key={`PortfolioShowcaseGlide-${index}`}>
                        <PortfolioShowcaseItem key={`PortfolioShowcaseItem-${index}`}>
                          <Box {...portfolioImage}>
                            <GatsbyImage
                              src={
                                (portfolioItem.image !== null)
                                  ? portfolioItem.image.childImageSharp
                                      .gatsbyImageData
                                  : {}
                              }
                              objectFit={"cover"}
                              alt={`PortfolioImage-${index + 1}`}
                            />
                          </Box>
                          <Box {...portfolioDetails}>
                            {portfolioItem.link && (
                              <PortfolioLink>
                                <a
                                  href={portfolioItem.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  DISCOVER MORE
                                </a>
                              </PortfolioLink>
                            )}
                            <Heading
                              content={portfolioItem.title}
                              {...titleStyle}
                            />
                            <Text
                              content={portfolioItem.description}
                              {...detailsStyle}
                            />
                            {portfolioItem.buildWith ? (
                              <BuiltWith>
                                {portfolioItem.buildWith.map((item, index) => (
                                  <p key={`buildWith-item-${index}`}>
                                    {item.content}
                                  </p>
                                ))}
                              </BuiltWith>
                            ) : (
                              ""
                            )}
                          </Box>

                          <PortfolioMeta>
                            {portfolioItem.featuredOn && buildFeaturedOn(portfolioItem.featuredOn, index)}
                            {portfolioItem.serviceDone.map((item, index) => (
                              <MetaItem key={`services-done-item-${index}`}>
                                <p>{item.content}</p>
                              </MetaItem>
                            ))}
                          </PortfolioMeta>
                        </PortfolioShowcaseItem>
                      </GlideSlide>
                    ))}
                  </>
                </GlideCarousel>
              </TabPane>
            ))}
          </Tabs>
        </PortfolioShowcaseWrapper>
      </Container>
    </Box>
  );
};

const buildFeaturedOn = (featuredOn, metaItemIndex) => {
  if (featuredOn.length === 0) return undefined;
  let spacer;
  switch (featuredOn.length) {
    case 1:
      spacer = "";
      break;
    case 2:
      spacer = " &";
      break;
    default:
      spacer = " ,";
      break;
  }
  return (
      <MetaItem key={`featured-on-meta-item-${metaItemIndex}`} className="meta_featured">
        <>Featured on</>
        {featuredOn.map((it, index) => (
            <React.Fragment key={`featured-on-meta-item-box-${metaItemIndex+100}-${index}`}>
              <a href={it.link || "#"} target="_blank" rel="noreferrer">
                {it.title}
              </a>
              {index !== featuredOn.length - 1 ? spacer : ""}
            </React.Fragment>
        ))}
      </MetaItem>
  );
};

PortfolioShowcase.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

PortfolioShowcase.defaultProps = {
  sectionWrapper: {
    pt: ["60px", "80px", "100px", "110px", "150px"],
    pb: ["60px", "80px", "100px", "110px", "150px"],
  },
  secTitleWrapper: {
    width: ["100%", "100%", "60%", "50%", "50%"],
    mb: ["50px", "65px"],
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "30px"],
    fontWeight: "600",
    color: "#302b4e",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "20px"],
  },
  secDescription: {
    fontSize: ["15px", "16px"],
    fontWeight: "400",
    color: "#43414e",
    lineHeight: "1.5",
    mb: "0",
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ["30px 0 0 0", "40px 0 0 0", "0 0 0 30px", "0 50px", "0 50px"],
  },
  titleStyle: {
    fontSize: ["22px", "22px", "26px", "40px", "40px"],
    fontWeight: "600",
    color: "#302b4e",
    mb: "17px",
  },
  detailsStyle: {
    fontSize: ["15px", "15px", "15px", "16px", "16px"],
    fontFamily: 'Raleway, sans-serif',
    color: "#43414e",
    lineHeight: "1.5",
    mb: "0",
  },
};

export default PortfolioShowcase;
