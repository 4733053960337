import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
// import Button from 'common/components/Button';
// import Input from 'common/components/Input';
import Container from 'common/components/UI/Container';
import SocialProfile from '../SocialProfile';

import {
  FooterWrapper,
  // Newsletter,
  // FooterNav,
  CopyrightBox,
  // FooterNavItem,
} from './footer.style';
import { heart } from 'react-icons-kit/fa/heart';

const Footer = ({
  row,
  col,
  titleStyle,
  linkStyle,
  newsletterButton,
  copyrightStyle,
  contactItem,
  flexBox,
  contactTitle,
  contactInfo,
  noMargin,
}) => {
  const data = useStaticQuery(graphql`
    query {
      contactJson {
        direct {
          contactEmail
          contactEmailSubject
          contactPhoneNumber
        }
        socialProfiles {
          display
          items {
            icon
            url
          }
        }
      }
      menuJson {
        footer {
          label
          path
        }
      }
    }
  `);

  const contact = data.contactJson.direct;
  // const footerMenu = data.portfolioJson.FOOTER_MENU;
  const socialProfiles = data.contactJson.socialProfiles;

  return (
    <FooterWrapper id="footer">
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Box {...col}>
            <Heading
              as="h3"
              content="Interested?"
              {...titleStyle}
            />
            <a id='contact-footer-email' href={`mailto:${contact.contactEmail}?subject=${contact.contactEmailSubject}`}>
              <Heading as="h3" content="Get in touch" {...linkStyle} />
            </a>
          </Box>
          <Box {...col} {...flexBox}>
            <Box {...contactItem}>
              <Text content="Feel like mailing" {...contactTitle} />
              <Text content={contact.contactEmail} {...contactInfo} />
            </Box>
            <Box {...contactItem}>
              <Text content="Feel like talking" {...contactTitle} />
              <Text content={contact.contactPhoneNumber} {...contactInfo} />
            </Box>
          </Box>
          {/*<Box {...col}>*/}
            {/*<Heading as="h3" content="A treat for your inbox" {...titleStyle} />*/}
            {/*<Newsletter>*/}
            {/*  <Input*/}
            {/*    inputType="email"*/}
            {/*    placeholder="Email address"*/}
            {/*    iconPosition="right"*/}
            {/*    isMaterial={false}*/}
            {/*    className="email_input"*/}
            {/*    aria-label="email"*/}
            {/*  />*/}
            {/*  <Button {...newsletterButton} title="Subscribe" />*/}
            {/*</Newsletter>*/}
          {/*</Box>*/}
        </Box>

        {/*<Box {...row}>*/}
        {/*  <Box {...col}>*/}
        {/*    {socialProfiles.display && <SocialProfile*/}
        {/*        className="footer_social"*/}
        {/*        items={socialProfiles.items}*/}
        {/*        iconSize={40}*/}
        {/*    />}*/}
        {/*    <Text*/}
        {/*      as="span"*/}
        {/*      content="© 2023 All rights reserved. "*/}
        {/*      {...copyrightStyle}*/}
        {/*    />*/}
        {/*    <a href="#1">*/}
        {/*      <Text as="span" content="Invoid Vision BV" {...copyrightStyle} />*/}
        {/*    </a>*/}
        {/*  </Box>*/}
        {/*</Box>*/}

        <Box {...row} {...noMargin}>
          <Box {...col}>
            <Text
              as="span"
              content="Built & designed with passion and "
              {...copyrightStyle}
            />
            <Icon icon={heart} size={12} className="heart_sign" />
            <Text
              as="span"
              content=" by Invoid Vision"
              {...copyrightStyle}
            />
          </Box>
          {/*<Box {...col} {...flexBox}>*/}
          {/*  <FooterNav>*/}
          {/*    {footerMenu.map((item, index) => (*/}
          {/*      <FooterNavItem key={`footer-nav-item-${index}`}>*/}
          {/*        <a href={item.path || '#1'}>{item.label}</a>*/}
          {/*      </FooterNavItem>*/}
          {/*    ))}*/}
          {/*  </FooterNav>*/}
          {/*</Box>*/}
          <CopyrightBox {...col} {...flexBox}>
            {socialProfiles.display && <SocialProfile
                className="footer_social"
                items={socialProfiles.items}
                iconSize={40}
            />}
            <Text
                as="span"
                content=""
                {...copyrightStyle}
            />
            <Text
                as="span"
                content="© 2023 All rights reserved. Invoid Vision BV"
                {...copyrightStyle}
            />
          </CopyrightBox>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  titleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  newsletterButton: PropTypes.object,
  copyrightStyle: PropTypes.object,
  contactItem: PropTypes.object,
  flexBox: PropTypes.object,
  contactTitle: PropTypes.object,
  contactInfo: PropTypes.object,
  noMargin: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ml: '-15px',
    mr: '-15px',
    mb: ['0', '70px', '80px', '100px', '100px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3, 1 / 3],
    pl: '15px',
    pr: '15px',
    mb: ['40px', '0', '0', '0', '0', '0'],
  },
  titleStyle: {
    fontSize: ['16px', '18px'],
    fontWeight: '600',
    mb: ['20px', '25px'],
  },
  linkStyle: {
    fontSize: ['22px', '26px', '26px', '30px'],
    color: `primary`,
    mb: 0,
  },
  newsletterButton: {
    type: 'button',
    fontSize: '16px',
    pl: '20px',
    pr: '20px',
    colors: 'primary',
    minHeight: 'auto',
  },
  copyrightStyle: {
    fontSize: '14px',
    color: '#fff',
  },
  flexBox: {
    flexBox: true,
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  contactItem: {
    // width: 2 / 2
  },
  contactTitle: {
    fontSize: ['15x', '15px', '16px', '16px', '16px'],
    mb: '10px',
  },
  contactInfo: {
    fontSize: ['16x', '16px', '18px', '18px', '20px'],
    fontWeight: '500',
    mb: 0,
  },
  noMargin: {
    mb: '0',
  },
};

export default Footer;
